
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import { LoadCategories } from '../util/Enum';

import Cars from '~/components/svgs/quotes/Cars.vue';
import Agriculture from '~/components/svgs/quotes/Agriculture.vue';
import Palletspackages from '~/components/svgs/quotes/Palletspackages.vue';
import Boats from '~/components/svgs/quotes/Boats.vue';
import Containersportablebuildins from '~/components/svgs/quotes/Containersportablebuildins.vue';
import Generalhaulage from '~/components/svgs/quotes/Generalhaulage.vue';
import Heavyoversizedhaulage from '~/components/svgs/quotes/Heavyoversizedhaulage.vue';
import Householdfurniture from '~/components/svgs/quotes/Householdfurniture.vue';
import Machinerytransport from '~/components/svgs/quotes/Machinerytransport.vue';
import Motorcycles from '~/components/svgs/quotes/Motorcycles.vue';
import Other from '~/components/svgs/quotes/Other.vue';
import Othervehicles from '~/components/svgs/quotes/Othervehicles.vue';
import Petslivestock from '~/components/svgs/quotes/Petslivestock.vue';
import Refrigerated from '~/components/svgs/quotes/Refrigerated.vue';
import Rvcaravanstrailers from '~/components/svgs/quotes/Rvcaravanstrailers.vue';
import Trucktrailers from '~/components/svgs/quotes/Trucktrailers.vue';
import Wasterecycling from '~/components/svgs/quotes/Wasterecycling.vue';
import UteAndVanCargo from '~/components/svgs/quotes/UteAndVanCargo.vue';

const StarRating = () => import('vue-star-rating');

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel,
    Cars,
    Agriculture,
    Palletspackages,
    Boats,
    Containersportablebuildins,
    Generalhaulage,
    Heavyoversizedhaulage,
    Householdfurniture,
    Machinerytransport,
    Motorcycles,
    Other,
    Othervehicles,
    Petslivestock,
    Refrigerated,
    Rvcaravanstrailers,
    Trucktrailers,
    Wasterecycling,
    UteAndVanCargo,
    StarRating,
  },
  props: {
    carouselItems: {
      type: Array,
      default: () => [],
    },
    isJobs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      settings: {
        infinite: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        dotsClass: 'slick-dots custom-dot-class',
        initialSlide: 0,
        lazyLoad: 'ondemand',
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },

          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      LoadCategories,
      showRating: false,
    };
  },
  mounted() {
    this.showRating = true;
  },
};
