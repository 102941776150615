
import Svgtransport from '~/components/svgs/Svgtransport.vue';
import Svgcommunicate from '~/components/svgs/Svgcommunicate.vue';
import Svgnocommission from '~/components/svgs/Svgnocommission.vue';
import NewsAlertSlider from '~/components/NewsAlertSlider.vue';
import { SHOW_NEWS_ALERT } from '../util/constants';
import Svgchoice from '~/components/svgs/Svgchoice.vue';
import Svghours from '~/components/svgs/Svghours.vue';
import Svgnotification from '~/components/svgs/Svgnotification.vue';
import { UserManager } from 'oidc-client';
import configurations from '~/plugins/oidc-client-config.js';
import Playbtn from '~/components/svgs/Playbtn.vue';
import GetQuoteModal from '~/components/modal/GetQuoteModal';
import Multiselect from 'vue-multiselect';
import { LoadCategoriesDropdown } from '../util/Enum';
import Rating from '~/components/svgs/how-it-works/Rating.vue';
import Shelves from '~/components/svgs/how-it-works/Shelves.vue';
import Truck from '~/components/svgs/how-it-works/Truck.vue';

export default {
  components: {
    Rating,
    Shelves,
    Truck,
    Svgnotification,
    Svgtransport,
    Svgcommunicate,
    Svgchoice,
    Svghours,
    Svgnocommission,
    Playbtn,
    GetQuoteModal,
    Multiselect,
    NewsAlertSlider
  },
  data() {
    return {
      displayThumb: true,
      isModalVisible: false,
      componentedJobs: [],
      suburbCollectionList: [],
      suburbDeliveryList: [],
      collectionLocation: {},
      deliveryLocation: {},
      selectedCategory: null,
      loadCategories: LoadCategoriesDropdown,
      displayCategoryValidation: false,
      displayThumbHiW: true,
      displayThumbFC: true,

      clubs: [
        { id: 1, blockquote: '"Platform is easy to use..."', description: 'We moved a tractor for a great customer, Rodney. We got paid promptly, and he will happily use us again in the future. The Utransport platform is so easy to use.', reviewer: 'Darryl', designation: 'Carrier' },
        { id: 2, blockquote: '"Customer service is fantastic..."', description: 'Utransport is great! I can contact customers directly, and I don\'t have to message through a third party. Customer service is fantastic too, especially for us old blokes who aren\'t used to the computer!', reviewer: 'Mick', designation: 'Carrier' },
        { id: 3, blockquote: '"Was able to compare quotes..."', description: 'Delighted with the service from our transport carrier, Daniel. He was great with communication when we moved our caravan. Received a few quotes, so I was able to compare. Utransport System was so easy to use.', reviewer: 'Ashley', designation: 'Shipper' },
        { id: 4, blockquote: '"No charge for getting quotes..."', description: 'Moved a 5-ton excavator from Melbourne to Sydney for a job very easily. No charge for getting a quote, and I got 3 quotes so I could choose the most suitable. Great service!', reviewer: 'Sean', designation: 'Shipper' },
        { id: 5, blockquote: '"Great service, great people..."', description: 'Very professional, highly recommend. Great service, great people.', reviewer: 'Zoe', designation: 'Carrier' },
        { id: 6, blockquote: '"Quote within a few hours..."', description: 'Advertised a scissor lift from NT to NSW. I got a well-priced quote within a few hours, and picked up the next day. Couldn\'t recommend enough to save time.', reviewer: 'Joe', designation: 'Shipper' },
        { id: 7, blockquote: '"Couldn\'t have asked for better results..."', description: 'Excellent Website. I posted all the details of my job and the transporters contacted me directly. The tractor was delivered within a week of the job details being posted. Couldn\'t have asked for better result.', reviewer: 'Rodney', designation: 'Shipper' },
      ],

      paginatedClubs: [],
      nbPages: 0,
      nbRowPerPage: 3,
      currentPageIndex: 0
    };
  },
  head() {
    return {
      title: 'Online Interstate Truck Transport Hub in Australia - UTransport',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Looking for an online Interstate truck transport hub in Australia, make contact with UTransport - The place where you can meet with the best transport carriers.'
        },
        {
          hid: 'Facebook Tag',
          name: 'facebook-domain-verification',
          content: 'pmk7zcvk0ng32hvrtesszwz90kzlau'
        }
      ],
    };
  },

  computed: {
    formattedClubs() {
      return this.clubs.reduce((c, n, i) => {
        if (i % 4 === 0) c.push([]);
        c[c.length - 1].push(n);
        return c;
      }, []);
    },
    currentPageClubs() {
      this.createPages();

      return this.paginatedClubs[this.currentPageIndex];
    }
  },

  watch: {
    $route: {
      handler() {
        const currentPath = this.$route.path;
        if (SHOW_NEWS_ALERT.includes(currentPath)) {
          this.showNewsSlider = true
        }
        else {
          this.showNewsSlider = false

        }
      },
      deep: true,
      immediate: true
    },
  },
  created() {
    if (!process.server) {
      if (!this.$store.state.user.isLogin) {
        const userManager = new UserManager(configurations.authConfigHomeLogin);
        userManager
          .signinRedirectCallback()
          .then((res) => {
            if (res != null) {
              this.$store.commit('user/setToken', res.access_token);
              this.$store.commit('user/setTokenType', res.token_type);
              localStorage.setItem('token', res.access_token);
              localStorage.setItem('id_token', res.id_token);
              this.$store.dispatch('user/getUserDetails');
              this.$store.dispatch('user/getProfileDetails');
            }
          })
          .catch((err) => {
            return err;
          });
      }
    }
    this.completedJobs();
  },
  methods: {
    playFindCarrierVideo() {
      this.displayThumbFC = false;
      const vid = document.getElementById('find-carrier-video');
      vid.play();
    },
    playHowItWorksVideo() {
      this.displayThumbHiW = false;
      const vid = document.getElementById('request-transport-video');
      vid.play();
    },
    categoryOnChange() {
      this.displayCategoryValidation = false;
    },
    goToGetQuote() {
      if (this.selectedCategory) {
        // Contruct the query params by given options by user
        var queryParams = { category: this.selectedCategory };
        if (this.collectionLocation) {
          queryParams.collectionSuburb = this.collectionLocation.suburb;
          queryParams.collectionPostalcode = this.collectionLocation.postcode;
        }
        if (this.deliveryLocation) {
          queryParams.deliverySuburb = this.deliveryLocation.suburb;
          queryParams.deliveryPostalcode = this.deliveryLocation.postcode;
        }

        const categoryArr = LoadCategoriesDropdown.filter(x => x.text == this.selectedCategory);
        // Re-direct user to get a quote form
        this.$router.push({ path: `/gettransportquote/${encodeURIComponent(categoryArr[0].routeParam)}`, query: { ...queryParams } });
      } else {
        this.displayCategoryValidation = true;
      }
    },
    closeQuoteModal() {
      this.isModalVisible = false;
    },
    completedJobs() {
      const payload = {
        page: 1,
        size: 100,
      };
      // payload.user = this.$store.state.user
      this.$store
        .dispatch('jobs/completedJobsToDisplay', payload)
        .then((res) => {
          if (res.result) {
            this.componentedJobs = res.result;
          }
        });
    },
    goToLoads() {
      this.$router.push({
        path: '/loads-board',
      });
    },
    goToHowItWorks() {
      this.$router.push({
        path: '/get-transport-quotes',
      });
    },
    redirectToBecomeACarrier() {
      this.$router.push({
        path: '/become-a-carrier',
      });
    },
    customLabel({ suburb, postcode }) {
      if (suburb && postcode) {
        return `${suburb} – ${postcode}`;
      }
      return 'Suburb/postcode';
    },
    showQuoteModal() {
      this.isModalVisible = true;
    },
    searchCollectionSuburb(searchString) {
      if (searchString) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          const data = {
            searchString,
          };
          this.$store.dispatch('lookup/getSuburbs', data).then((res) => {
            this.suburbCollectionList = res;
            if (this.payload) {
              this.collectionLocation = this.suburbCollectionList.filter(
                (e) => e.postcode == this.load.collectionLocation.postCode
              );
            }
          });
        }, 500);
      }
    },
    searchDeliverySuburb(searchString) {
      if (searchString && searchString.length > 1) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          const data = {
            searchString,
          };
          this.$store.dispatch('lookup/getSuburbs', data).then((res) => {
            this.suburbDeliveryList = res;
            if (this.payload) {
              this.deliveryLocation = this.suburbDeliveryList.filter(
                (e) => e.postcode == this.load.deliveryLocation.postCode
              );
            }
          });
        }, 500);
      }
    },

    currentPage(i) {
      return i - 1 === this.currentPageIndex;
    },
    createPages() {

      let lengthAll = Object.keys(this.clubs).length;
      this.nbPages = 0;
      for (let i = 0; i < lengthAll; i = i + this.nbRowPerPage) {
        this.paginatedClubs[this.nbPages] = this.clubs.slice(
          i,
          i + this.nbRowPerPage
        );
        this.nbPages++;
      }
    },
    goto(i) {
      this.currentPageIndex = i - 1;
    }
  },
};
