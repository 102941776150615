/**
 * Constants used
 */

export const CARRIER = 'Carrier';
export const SHIPPER = 'Shipper';

export const DEFAULT_RADIUS = 200;

export const SHOW_NEWS_ALERT = ['/', '/get-transport-quotes', '/contact-us', '/become-a-carrier']


export const RegionFilters = [
    { value: 'QLD All', prefixes:[40, 41, 42, 43, 44, 45, 46, 47, ]},
    { value: 'QLD Central', prefixes:[45, 46, 47]},
    { value: 'QLD Brisbane Region', prefixes:[40, 41, 42]},
    { value: 'QLD North', prefixes:[48, 47]},
    { value: 'QLD South', prefixes:[42, 43, 44]},
    { value: 'NSW All', prefixes:[20, 21, 22, 23, 24, 35, 26, 28]},
    { value: 'NSW Sydney Region', prefixes:[20, 21, 22, 23]},
    { value: 'NSW North', prefixes:[24, 23, 28]},
    { value: 'NSW South', prefixes:[29, 26, 25]},
    { value: 'NSW West', prefixes:[26, 27, 28]},
    { value: 'VIC All', prefixes:[32, 33, 34, 35, 36, 37, 38]},
    { value: 'VIC East', prefixes:[37, 38]},
    { value: 'VIC Melbourne', prefixes:[30, 31, 32]},
    { value: 'VIC North', prefixes:[34, 35, 36, 37]},
    { value: 'VIC West', prefixes:[32, 33, 34, 35]},
    { value: 'WA All', prefixes:[60, 61, 62, 63, 64, 65, 66, 67]},
    { value: 'WA Central', prefixes:[64, 66, 67]},
    { value: 'WA East', prefixes:[60, 61, 62, 65, 67]},
    { value: 'WA Perth', prefixes:[60, 61]},
    { value: 'WA North', prefixes:[67]},
    { value: 'WA South', prefixes:[62, 63, 64]},
    { value: 'SA All', prefixes:[50, 51, 52, 53, 54, 55, 56, 57]},
    { value: 'SA Central', prefixes:[54, 56, 57]},
    { value: 'SA Adelaide Region', prefixes:[50, 51, 52]},
    { value: 'SA North', prefixes:[57]},
    { value: 'SA South', prefixes:[52, 53, 54, 55]},
    { value: 'SA West', prefixes:[56]},
    { value: 'NT All', prefixes:[]},
    { value: 'NT Central', prefixes:[]},
    { value: 'NT Darwin Region', prefixes:[]},
    { value: 'TAS', prefixes:[7]}
  ]