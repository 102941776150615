

export default {
  components: {
    [process.client && 'MarqueeText']: () => import('vue-marquee-text-component')
  },
  data() {
    return{
      duration: 40,
      formattedNewsString: '',
      sticky: false,
      stickyVal: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    subscription() {
      return this.$store.state.payment.subscription;
    },
  },
  created() {
    this.getNewsList()
  },
  mounted() {
    window.scrollTo(0, 0);
    const displayWidth = window.innerWidth
    const isBecome = this.$route.path === '/become-a-carrier';
    this.stickyVal = this.$refs.news_slider?.getBoundingClientRect().top + (displayWidth> 1000 ? (isBecome? 150 :-150) : displayWidth> 570 ?(isBecome? 400: 90):(isBecome? 950: 90))
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async getNewsList() {
      this.$store.dispatch('newsAlerts/getNewsAlerts')
        .then((res) => {
          if(res.result){
            res.result?.forEach(news => {
            this.formattedNewsString = this.formattedNewsString.concat('<li class="news-item">' + news.message + ' </li>' + '<li class="empty-space">|</li>' )
          });
        }})
        .catch(() => {
        });
    },
    handleScroll () {
      this.sticky = window.pageYOffset >= this.stickyVal ? window.pageYOffset >= this.stickyVal : false
    }
  }
};
